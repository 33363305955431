.footers{
    position: relative;
    z-index: 1;
    display: grid;
    // grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  ------------
  .footer{
    position:absolute;
  }
  
  .footer.one4 {
    grid-column: 1;
    grid-row: 8;
    
  }
  .footer.two4 {
    grid-column: 2;  
    grid-row: 10;
    
  }
//   .photo1.one2 {
//     grid-column: 1;
//     grid-row: 5;
    
    
//   }
//   .photo1.two2 {
//     grid-column: 9;  
//     grid-row: 3;
    
//   }

  
  