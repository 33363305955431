.contacts{
    position: absolute;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  ------------
  .contact{
    position:relative;
  }
  
  .contact.one3 {
    grid-column: 9;
    grid-row: 8;
    
  }
  .footer.two4 {
    grid-column: 4;  
    grid-row: 10;
    
  }
//   .photo1.one2 {
//     grid-column: 1;
//     grid-row: 5;
    
    
//   }
//   .photo1.two2 {
//     grid-column: 9;  
//     grid-row: 3;
    
//   }

  
  